mat-icon {
  &.pointer {
    cursor: pointer;
  }

  &.color {
    &.red { color: red; }
    &.blue { color: blue; }
    &.green { color: green; }
  }

  &.favorite {
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

    &.true {
      color: blue;
      text-shadow: none;
    }
  }

  &.info-icon {
    cursor: pointer;
    color: dodgerblue;
  }
}
