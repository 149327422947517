mat-cell {
  &::first-of-type {
    padding-left: 0;
  }

  &::last-of-type {
    margin-right: 0;
  }

  &.expanded {
    display: block;
    margin-left: 30px;
    padding: 5px 0 20px 0;
  }
}

.mat-column {
  a {
    cursor: pointer;
    margin: 0 10px;
  }
}

mat-row {
  &.clickable {
    position: relative;
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  }

  &.expanded {
    border-bottom-color: transparent;
  }
}

/*
.element-row {
*/
