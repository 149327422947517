article button.button-back {
  position: absolute;
    top: 0;
    right: 0;
  width: 200px;
  height: 40px;
  z-index: 125;
}

button {
  color: black;
  &.mat-button {
    &.delete {
      color: red;
    }
    span {
      font-size: 1.05rem;
      text-transform: uppercase;
    }
  }

  &.mat-raised-button {
    text-transform: uppercase;
  }

  mat-icon {
    width: 0.9rem;
    height: 0.9rem;
  }

  mat-icon + span {
    position: relative;
      top: 1px;
    font-size: 0.9rem;
  }
}

mat-cell {
  .mat-button {
    span {
      font-size: 0.8rem !important;
    }
  }
}
