::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) {
  .mat-slide-toggle-bar {
    background-color: rgba(0, 230, 0, .54);
  }
}

mat-card {
  margin-bottom: 20px;
}

.mat-snack-bar-container {
  white-space: pre-wrap !important;
}

/* MAT TABS ----------------------------------------------------------------- */
.mat-tab-label-container {
  margin-bottom: 1.5rem;
}

.mat-tab-labels {
  .mat-tab-label {
    color: $app-primary-color-inactive;
  }

  .mat-tab-label-active {
    color: $app-primary-color;
  }
  .mat-tab-label-content {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

/* MAT TABS (NAV BAR) ------------------------------------------------------- */
nav.mat-tab-nav-bar {
  .mat-tab-links {
    display: inline-flex;
      flex-flow: row wrap;
      justify-content: space-between;

    a {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: normal;

      &.mat-tab-label {
        color: $app-primary-color-inactive;
      }

      &.mat-tab-label-active {
        opacity: 1;
        color: $app-primary-color;
      }
    }
  }
}

