@use '@angular/material' as mat;

@include mat.core();

$app-primary-color: rgb(6, 28, 37);
$app-secondary-color: lightgray;
$app-primary-color-inactive: darkslategray;

/*
$app-secondary-color: #B1B4C4;
$app-primary-color-inactive: #686870;
*/

$custom-grey: (
  50: #617880,
  100: #5c737b,
  200: #556c74,
  300: #475e66,
  400: #243b43,
  500: $app-primary-color,
  600: #240d05,
  700: #382119,
  800: #574038,
  900: #786159,
  A100: #667d85,
  A200: #556c74,
  A400: #243b43,
  A700: rgb(6, 28, 37),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  )
);

$app-primary: mat.define-palette($custom-grey, A700);
$app-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme:
  mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);
