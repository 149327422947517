.form-group {
  margin-bottom: 1rem;

  &.inline {
    span.label {
      margin-right: 1.5rem;
      font-weight: bold;
    }

    mat-radio-group {
      mat-radio-button {
        margin-right: 2rem;

        &::last-of-type {
          margin-right: 0;
        }
      }
    }

    mat-form-field {
      min-width: 33%;
    }

  }
  mat-form-field {
    min-width: 33%;
    &.max {
      width: 100%;
    }
  }
}


.mat-checkbox-input[aria-checked="true"] ~ .mat-checkbox-background {
  background-color: green;
}
