@import './theme.scss';

* {
  font-family: 'Comfortaa', cursive;
}

body {
  margin: 0;
}

article {
  position: relative;

  &.component {
    margin: 5% 10% 10% 10%;

    h1 {
      margin: 0;
      padding-bottom: 2.5rem;
      font-size: 32px;
    }

    h2 {
      font-size: 26px;
    }

    h3 {
      padding: 1rem 2rem;
      font-size: 16px;
      margin-bottom: 0;

      &.primary {
        color: white;
        background-color: $app-primary-color;
      }
      &.secondary {
        margin-top: 0;
        background-color: $app-secondary-color;
      }
    }

    > h3,
    button.button-back ~ h3 {
      margin-top: 0;
    }

    section {
      background-color: white;
      padding: 1.5rem 2rem;
      border: 1px solid $app-secondary-color;
      border-top: none;

      section {
        padding: 0;
        border: none;
      }
    }

    article {
      margin: 0;
    }
  }
}

@media (max-width: 840px) {
  article {
    margin: 0 !important;
    padding-top: 1rem;

    &.component {
      h1 {
        padding-bottom: 1.5rem;
      }
      section {
        padding: 1rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@import './_material-overwrites.scss';
@import './_form.scss';
@import './_buttons.scss';
@import './_icons.scss';
@import './_tables.scss';
